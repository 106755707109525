<template>
  <div class="page-customer-orders">
    <v-row justify="space-between" align="center">
      <span class="text-wrap display-1 ma-3">{{ $t('orders') }}</span>
    </v-row>
    <v-divider class="mt-2 mb-4"></v-divider>

    <v-card class="ordersTableCard px-4">
      <v-card-title>
        <DataTableFilter
          v-if="dataTableFilters"
          class="col-6 pl-0"
          ref="filter"
          @update="updateFilter"
          :filters="dataTableFilters"
        ></DataTableFilter>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="fas fa-search"
          :label="$t('Search')"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="customerOrders"
        :custom-filter="customFilter"
        :search="search"
        sort-by="created"
        :sort-desc="true"
        :footer-props="{
          itemsPerPageOptions: [15, 50, 100, -1]
        }"
        class="cursorTable"
        @click:row="goToPageItem($event)"
      >
        <template v-slot:[`header.paymentStatus`]>
          <span>
            {{ $t('PaymentStatus') }}
          </span>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                small
                class="ml-0"
              >
                fas fa-question-circle
              </v-icon>
            </template>
            <span>
              {{ $t('PrivateWarehousesOnly') }}
            </span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <router-link
            :to="{name: 'Order', params: { supplierUUID: `${$route.params.supplierUUID}`, orderUUID: `${item._id}`}}"
            class="text-decoration-none"
          >
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <template v-if="item.status === 'NEW' || item.status === 'SCHEDULED'">
                    <v-icon
                      small
                      color="info"
                      class="mr-2"
                    >far fa-clock</v-icon>
                  </template>
                  <template v-if="item.status === 'SUBMITTED'">
                    <v-icon
                      small
                      color="warning"
                      class="mr-2"
                    >
                      far fa-calendar-alt
                    </v-icon>
                  </template>
                  <template v-if="item.status === 'INPROGRESS' && item?.quote?.dispatch?.status !== 'CANCELED'">
                    <v-icon
                      small
                      color="primary"
                      class="mr-2"
                    >
                      fas fa-spinner
                    </v-icon>
                  </template>
                  <template v-if="item.status === 'DELIVERED'">
                    <v-icon
                      small
                      color="success"
                      class="mr-2"
                    >fas fa-check</v-icon>
                  </template>
                  <template v-if="item.status === 'CANCELED' || item?.quote?.dispatch?.status === 'CANCELED'">
                    <v-icon
                      small
                      color="error"
                      class="mr-2"
                    >fas fa-ban</v-icon>
                  </template>
                </span>
              </template>

              {{ $t(item.status) }}
            </v-tooltip>
          </router-link>
        </template>
        <template v-slot:[`item.public`]="{ item }">
          <router-link
            :to="{name: 'Order', params: {supplierUUID: $route.params.supplierUUID, quoteUUID: item._id}}"
            class="text-decoration-none"
            :title="item.public ? 'CMT' : $t('PrivateWarehouse')"
          >
            <v-img 
              v-if="item.public"
              :src="require('/src/assets/logo_cut.png')"
              :lazy-src="require('/src/assets/logo_cut.png')"
              class="mx-auto"
              contain
              width="30"
              height="30"
            >
            </v-img>
            <v-icon
              v-else
              small
              color="privateSupplier"
            >
              fas fa-warehouse
            </v-icon>
          </router-link>
        </template>
        <template v-slot:[`item.invoiceNumber`]="{ item }">
          <router-link
            :to="{name: 'Order', params: { supplierUUID: `${$route.params.supplierUUID}`, orderUUID: `${item._id}`}}"
            class="text-decoration-none"
          >
            {{ item.invoiceNumber }}
          </router-link>
        </template>
        <template v-slot:[`item.quantity`]="{ item }">
          <router-link
            :to="{name: 'Order', params: { supplierUUID: `${$route.params.supplierUUID}`, orderUUID: `${item._id}`}}"
            class="text-decoration-none"
          >
            {{ item.quote.dispatch.items.reduce((previousValue, item) => {return previousValue + item.quantity}, 0) }}
          </router-link>
        </template>
        <template v-slot:[`item.technology`]="{ item }">
          <router-link
            :to="{name: 'Order', params: { supplierUUID: `${$route.params.supplierUUID}`, orderUUID: `${item._id}`}}"
            class="text-decoration-none"
          >
            <v-chip
              class="ma-1"
              v-for="technology in new Set(item.quote.dispatch.items.map(i => {return i?.manufacturingInformation?.dnaTechnology}).filter(t => {return !!t}))"
              :key="technology"
            >
              {{ $t(technology) }}
            </v-chip>
          </router-link>
        </template>
        <template v-slot:[`item.material`]="{ item }">
          <router-link
            :to="{name: 'Order', params: { supplierUUID: `${$route.params.supplierUUID}`, orderUUID: `${item._id}`}}"
            class="text-decoration-none"
          >
            <v-chip
              class="ma-1"
              v-for="material in new Set(item.quote.dispatch.items.map(i => {return i?.manufacturingInformation?.dnaMaterial}).filter(t => {return !!t}))"
              :key="material"
            >
              {{ $t(material) }}
            </v-chip>
          </router-link>
        </template>
        <template v-slot:[`item.paymentStatus`]="{ item }">
          <router-link
            :to="{name: 'Order', params: { supplierUUID: `${$route.params.supplierUUID}`, orderUUID: `${item._id}`}}"
            class="text-decoration-none"
          >
            <v-tooltip top v-if="!item.public && isOrderWithBilling(item)">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-icon
                    :color="item.paymentStatus === 'PAID' ? 'success' : 'info'"
                  >
                    far fa-credit-card
                  </v-icon>
                  <v-icon
                    v-if="item.paymentStatus === 'PAID'"
                    color="success"
                    small
                    class="ml-n1 mb-n5"
                  >
                    fas fa-check-circle
                  </v-icon>
                  <v-icon
                    v-else-if="item.paymentStatus === 'WAITING'"
                    color="info"
                    small
                    class="ml-n1 mb-n5"
                  >
                    fas fa-clock
                  </v-icon>
                </div>
              </template>
              <span v-if="item.paymentStatus === 'PAID'">
                {{ $t('PAID') }}
              </span>
              <span v-else-if="item.paymentStatus === 'WAITING'">
                {{ $t('WAITING') }}
              </span>
            </v-tooltip>
          </router-link>
        </template>
        <template v-slot:[`item.invoice`]="{ item }">
          <router-link
            :to="{name: 'Order', params: { supplierUUID: `${$route.params.supplierUUID}`, orderUUID: `${item._id}`}}"
            class="text-decoration-none"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-icon :color="isOrderWithBilling(item) ? item.quote.dispatch.invoiceFile ? 'success' : 'error' : 'grey' " class="mr-2">
                    fas fa-file-pdf
                  </v-icon>
                </div>
              </template>
              <span v-if="item.quote.dispatch.invoiceFile">
                {{ $t('InvoiceAdded') }}
              </span>
              <span v-else-if="isOrderWithBilling(item)">
                {{ $t('InvoiceMissing') }}
              </span>
              <span v-else>
                {{ $t('NotApplicable') }}
              </span>
            </v-tooltip>
          </router-link>
        </template>
        <template v-slot:[`item.created`]="{ item }">
          <router-link
            :to="{name: 'Order', params: { supplierUUID: `${$route.params.supplierUUID}`, orderUUID: `${item._id}`}}"
            class="text-decoration-none"
          >
            {{ $d(new Date(item.created), "long", $userLocale) }}
          </router-link>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn
            :to="{name: 'Order', params: { supplierUUID: `${$route.params.supplierUUID}`, orderUUID: `${item._id}`}}"
            class="primary"
            :title="$t('view')"
          >
            <v-icon small class="far fa-eye"></v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>

import { ApiErrorParser, CustomFilterForSearchDatatable, DataTableFilter } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageCustomerOrders.i18n.json');

export default {
  name: 'PageCustomerOrders',
  components: {
    DataTableFilter
  },
  props: {
    /**
     * Customer
     */
    customer: {
      type: Object,
      default: null
    },
    /**
     * Users
     */
    customerUsers: {
      type: Array,
      default: null
    },
    /**
     * Quotes
     */
    customerQuotes: {
      type: Array,
      default: null
    },
    /**
     * Orders
     */
    customerOrders: {
      type: Array,
      default: null
    },
    /**
     * Parts
     */
    customerParts: {
      type: Array,
      default: null
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      search: '',
      headers: [],
      ordersStatusFilter: [],
      invoiceStatusFilter: [],
      typeFilter: [],
      dataTableFilters: null
    };
  },
  mounted() {
    this.reinitializeFilterCount();
  },
  created() {
    this.headers = [
      {
        text: this.$t('status'),
        value: 'status',
        align: 'center',
        filter: (value, search, item) => {
          if (!this.ordersStatusFilter 
            || (this.ordersStatusFilter && this.ordersStatusFilter.length === 0)
          ) {
            return true;
          }
          if(item?.quote?.dispatch?.status === 'CANCELED' && this.ordersStatusFilter?.includes('CANCELED')) {
            return true;
          }
          if(['NEW','SCHEDULED'].includes(item.status) && this.ordersStatusFilter?.includes('NEW')) {
            return true;
          }
          if(item.status === 'INPROGRESS' && this.ordersStatusFilter?.includes('INPROGRESS')) {
            return true;
          } 
          if(item.status === 'DELIVERED' && this.ordersStatusFilter?.includes('DELIVERED')) {
            return true;
          } 
          if(item.status === 'CANCELED' && this.ordersStatusFilter?.includes('CANCELED')) {
            return true;
          }
        },
      },
      {
        text: this.$t('orderNumber'),
        value: 'invoiceNumber',
        align: 'center',
      },
      {
        text: this.$t('quantity'),
        value: 'quantity',
        align: 'center',
        sortable: false
      },
      {
        text: this.$t('technology'),
        value: 'technology',
        align: 'center',
        sortable: false
      },
      {
        text: this.$t('material'),
        value: 'material',
        align: 'center',
        sortable: false
      },
      {
        text: this.$t('PaymentStatus'),
        value: 'paymentStatus',
        align: 'center',
        sortable: false
      },
      {
        text: this.$t('Invoice'),
        value: 'invoice',
        align: 'center',
        sortable: false,
        filter: (value, search, item) => {
          if (!this.invoiceStatusFilter || this.invoiceStatusFilter.length === 0) {
            return true;
          }
          if(!this.isOrderWithBilling(item) && this.invoiceStatusFilter.includes('notApplicable')) {
            return true;
          }
          if (item.quote.dispatch.invoiceFile && this.invoiceStatusFilter.includes('uploaded')) {
            return true;
          }
          if (!item.quote.dispatch.invoiceFile && this.invoiceStatusFilter.includes('missing')) {
            return true;
          }
        },
      },
      { text: this.$t('created'),
        value: 'created',
        align: 'center' },
      {
        text: this.$t('action'),
        value: 'action',
        align: 'center',
        sortable: false,
      },
    ];
    this.dataTableFilters = {
      status: [
        {
          name: this.$t('NEW'),
          value: 'NEW',
          icon: 'far fa-clock',
          number: 0
        },
        {
          name: this.$t('INPROGRESS'), 
          value: 'INPROGRESS', 
          icon: 'fas fa-spinner',
          number: 0
        },
        {
          name: this.$t('DELIVERED'), 
          value: 'DELIVERED', 
          icon: 'fas fa-check', 
          iconColor: 'success',
          number: 0
        },
        {
          name: this.$t('CANCELED'), 
          value: 'CANCELED', 
          icon: 'fas fa-ban', 
          iconColor: 'error',
          number: 0
        }
      ],
      invoice: [
        {
          name: this.$t('Uploaded'),
          value: 'uploaded',
          icon: 'fas fa-file-pdf',
          iconColor: 'success',
          number: 0
        },
        {
          name: this.$t('Missing'), 
          value: 'missing', 
          icon: 'fas fa-file-pdf',
          iconColor: 'error',
          number: 0
        },
        {
          name: this.$t('NotApplicable'), 
          value: 'notApplicable', 
          icon: 'fas fa-file-pdf',
          iconColor: 'grey',
          number: 0
        },
      ],
    };
    this.reinitializeFilterCount();
  },
  updated() {
    this.reinitializeFilterCount();
  },
  methods: {
    reinitializeFilterCount() {
      for(const key in this.dataTableFilters) {
        for(const filter of this.dataTableFilters[key]) {
          filter.number = 0;
        }
      }
      this.customerOrders.forEach((order) => {
        if(order?.quote?.dispatch?.status === 'CANCELED') {
          this.dataTableFilters.status[3].number += 1;
        } else {
          switch (order.status) {
          case 'NEW':
          case 'SCHEDULED':
            this.dataTableFilters.status[0].number += 1;
            break;
          case 'INPROGRESS':
            this.dataTableFilters.status[1].number += 1;
            break;
          case 'DELIVERED':
            this.dataTableFilters.status[2].number += 1;
            break;
          case 'CANCELED':
            this.dataTableFilters.status[3].number += 1;
            break;
          }
        }

        if(!this.isOrderWithBilling(order)) {
          this.dataTableFilters.invoice[2].number ++;
        } else if(order.quote.dispatch.invoiceFile) {
          this.dataTableFilters.invoice[0].number ++;
        } else {
          this.dataTableFilters.invoice[1].number ++;
        }
      });
    },
    customFilter(items, search, filter) {
      return CustomFilterForSearchDatatable.customFilter(items, search, this);
    },
    updateFilter(updatedFilters) {
      this.ordersStatusFilter = updatedFilters.status;
      this.invoiceStatusFilter = updatedFilters.invoice;
      this.typeFilter = updatedFilters.type;
    },
    goToPageItem(item) {
      this.$router.push({name: 'Order', params: { supplierUUID: `${this.$route.params.supplierUUID}`, orderUUID: `${item._id}`}});
    },
    isOrderWithBilling(order) {
      return order.billing && order.status !== 'CANCELED' && order.quote.dispatch.status !== 'CANCELED';
    }
  },
};
</script>
