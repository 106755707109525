var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-customer-orders"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(_vm._s(_vm.$t('orders')))])]), _c('v-divider', {
    staticClass: "mt-2 mb-4"
  }), _c('v-card', {
    staticClass: "ordersTableCard px-4"
  }, [_c('v-card-title', [_vm.dataTableFilters ? _c('DataTableFilter', {
    ref: "filter",
    staticClass: "col-6 pl-0",
    attrs: {
      "filters": _vm.dataTableFilters
    },
    on: {
      "update": _vm.updateFilter
    }
  }) : _vm._e(), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "append-icon": "fas fa-search",
      "label": _vm.$t('Search'),
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('v-data-table', {
    staticClass: "cursorTable",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.customerOrders,
      "custom-filter": _vm.customFilter,
      "search": _vm.search,
      "sort-by": "created",
      "sort-desc": true,
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      }
    },
    on: {
      "click:row": function clickRow($event) {
        return _vm.goToPageItem($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "header.paymentStatus",
      fn: function fn() {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t('PaymentStatus')) + " ")]), _c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on;
              return [_c('v-icon', _vm._g({
                staticClass: "ml-0",
                attrs: {
                  "small": ""
                }
              }, on), [_vm._v(" fas fa-question-circle ")])];
            }
          }])
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('PrivateWarehousesOnly')) + " ")])])];
      },
      proxy: true
    }, {
      key: "item.status",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Order',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                orderUUID: "".concat(item._id)
              }
            }
          }
        }, [_c('v-tooltip', {
          attrs: {
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var _item$quote, _item$quote$dispatch, _item$quote2, _item$quote2$dispatch;

              var on = _ref3.on;
              return [_c('span', _vm._g({}, on), [item.status === 'NEW' || item.status === 'SCHEDULED' ? [_c('v-icon', {
                staticClass: "mr-2",
                attrs: {
                  "small": "",
                  "color": "info"
                }
              }, [_vm._v("far fa-clock")])] : _vm._e(), item.status === 'SUBMITTED' ? [_c('v-icon', {
                staticClass: "mr-2",
                attrs: {
                  "small": "",
                  "color": "warning"
                }
              }, [_vm._v(" far fa-calendar-alt ")])] : _vm._e(), item.status === 'INPROGRESS' && (item === null || item === void 0 ? void 0 : (_item$quote = item.quote) === null || _item$quote === void 0 ? void 0 : (_item$quote$dispatch = _item$quote.dispatch) === null || _item$quote$dispatch === void 0 ? void 0 : _item$quote$dispatch.status) !== 'CANCELED' ? [_c('v-icon', {
                staticClass: "mr-2",
                attrs: {
                  "small": "",
                  "color": "primary"
                }
              }, [_vm._v(" fas fa-spinner ")])] : _vm._e(), item.status === 'DELIVERED' ? [_c('v-icon', {
                staticClass: "mr-2",
                attrs: {
                  "small": "",
                  "color": "success"
                }
              }, [_vm._v("fas fa-check")])] : _vm._e(), item.status === 'CANCELED' || (item === null || item === void 0 ? void 0 : (_item$quote2 = item.quote) === null || _item$quote2 === void 0 ? void 0 : (_item$quote2$dispatch = _item$quote2.dispatch) === null || _item$quote2$dispatch === void 0 ? void 0 : _item$quote2$dispatch.status) === 'CANCELED' ? [_c('v-icon', {
                staticClass: "mr-2",
                attrs: {
                  "small": "",
                  "color": "error"
                }
              }, [_vm._v("fas fa-ban")])] : _vm._e()], 2)];
            }
          }], null, true)
        }, [_vm._v(" " + _vm._s(_vm.$t(item.status)) + " ")])], 1)];
      }
    }, {
      key: "item.public",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Order',
              params: {
                supplierUUID: _vm.$route.params.supplierUUID,
                quoteUUID: item._id
              }
            },
            "title": item.public ? 'CMT' : _vm.$t('PrivateWarehouse')
          }
        }, [item.public ? _c('v-img', {
          staticClass: "mx-auto",
          attrs: {
            "src": require('/src/assets/logo_cut.png'),
            "lazy-src": require('/src/assets/logo_cut.png'),
            "contain": "",
            "width": "30",
            "height": "30"
          }
        }) : _c('v-icon', {
          attrs: {
            "small": "",
            "color": "privateSupplier"
          }
        }, [_vm._v(" fas fa-warehouse ")])], 1)];
      }
    }, {
      key: "item.invoiceNumber",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Order',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                orderUUID: "".concat(item._id)
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.invoiceNumber) + " ")])];
      }
    }, {
      key: "item.quantity",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Order',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                orderUUID: "".concat(item._id)
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.quote.dispatch.items.reduce(function (previousValue, item) {
          return previousValue + item.quantity;
        }, 0)) + " ")])];
      }
    }, {
      key: "item.technology",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Order',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                orderUUID: "".concat(item._id)
              }
            }
          }
        }, _vm._l(new Set(item.quote.dispatch.items.map(function (i) {
          var _i$manufacturingInfor;

          return i === null || i === void 0 ? void 0 : (_i$manufacturingInfor = i.manufacturingInformation) === null || _i$manufacturingInfor === void 0 ? void 0 : _i$manufacturingInfor.dnaTechnology;
        }).filter(function (t) {
          return !!t;
        })), function (technology) {
          return _c('v-chip', {
            key: technology,
            staticClass: "ma-1"
          }, [_vm._v(" " + _vm._s(_vm.$t(technology)) + " ")]);
        }), 1)];
      }
    }, {
      key: "item.material",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Order',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                orderUUID: "".concat(item._id)
              }
            }
          }
        }, _vm._l(new Set(item.quote.dispatch.items.map(function (i) {
          var _i$manufacturingInfor2;

          return i === null || i === void 0 ? void 0 : (_i$manufacturingInfor2 = i.manufacturingInformation) === null || _i$manufacturingInfor2 === void 0 ? void 0 : _i$manufacturingInfor2.dnaMaterial;
        }).filter(function (t) {
          return !!t;
        })), function (material) {
          return _c('v-chip', {
            key: material,
            staticClass: "ma-1"
          }, [_vm._v(" " + _vm._s(_vm.$t(material)) + " ")]);
        }), 1)];
      }
    }, {
      key: "item.paymentStatus",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Order',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                orderUUID: "".concat(item._id)
              }
            }
          }
        }, [!item.public && _vm.isOrderWithBilling(item) ? _c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref10) {
              var on = _ref10.on;
              return [_c('div', _vm._g({}, on), [_c('v-icon', {
                attrs: {
                  "color": item.paymentStatus === 'PAID' ? 'success' : 'info'
                }
              }, [_vm._v(" far fa-credit-card ")]), item.paymentStatus === 'PAID' ? _c('v-icon', {
                staticClass: "ml-n1 mb-n5",
                attrs: {
                  "color": "success",
                  "small": ""
                }
              }, [_vm._v(" fas fa-check-circle ")]) : item.paymentStatus === 'WAITING' ? _c('v-icon', {
                staticClass: "ml-n1 mb-n5",
                attrs: {
                  "color": "info",
                  "small": ""
                }
              }, [_vm._v(" fas fa-clock ")]) : _vm._e()], 1)];
            }
          }], null, true)
        }, [item.paymentStatus === 'PAID' ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('PAID')) + " ")]) : item.paymentStatus === 'WAITING' ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('WAITING')) + " ")]) : _vm._e()]) : _vm._e()], 1)];
      }
    }, {
      key: "item.invoice",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Order',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                orderUUID: "".concat(item._id)
              }
            }
          }
        }, [_c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref12) {
              var on = _ref12.on;
              return [_c('div', _vm._g({}, on), [_c('v-icon', {
                staticClass: "mr-2",
                attrs: {
                  "color": _vm.isOrderWithBilling(item) ? item.quote.dispatch.invoiceFile ? 'success' : 'error' : 'grey'
                }
              }, [_vm._v(" fas fa-file-pdf ")])], 1)];
            }
          }], null, true)
        }, [item.quote.dispatch.invoiceFile ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('InvoiceAdded')) + " ")]) : _vm.isOrderWithBilling(item) ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('InvoiceMissing')) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('NotApplicable')) + " ")])])], 1)];
      }
    }, {
      key: "item.created",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Order',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                orderUUID: "".concat(item._id)
              }
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$d(new Date(item.created), "long", _vm.$userLocale)) + " ")])];
      }
    }, {
      key: "item.action",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_c('v-btn', {
          staticClass: "primary",
          attrs: {
            "to": {
              name: 'Order',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                orderUUID: "".concat(item._id)
              }
            },
            "title": _vm.$t('view')
          }
        }, [_c('v-icon', {
          staticClass: "far fa-eye",
          attrs: {
            "small": ""
          }
        })], 1)];
      }
    }], null, true)
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }